import { getDuration } from 'components/utilities/get-duration';
import { getDisplayDate } from './get-display-date';
import {
  ASSESSMENT_EVENT_TYPES,
  WORKSHOP_EVENT_TYPES,
  eventTypes,
} from '../../../constants';
import { getDisplayStatusComponent, getDisplayStatus } from './render-status';
import { displayOpenDate } from './display-open-date';
import { getLocation } from './get-location';
import { getLinkedWorkshopEvents } from './get-linked-workshop-events';
import { getNumberOfAllowedAttempts } from './get-number-of-allowed-attempts';
import { Icon } from '@monash/portal-react';
import WorkshopAccordion from './WorkshopAccordion';
import { formatTimeDistance } from 'components/providers/data-provider/utils';
import DueDateOrTime from 'components/pages/upcoming/components/DueDateOrTime';
import ce from './exam-button.module.scss';
import fs from 'styles/font-styles.module.scss';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import { renderGrade } from './render-grade';
import { renderGradesList } from './render-grades-list';
import { GradesList } from './GradesList/GradesList';
import { GRADE_SCALE_TYPES_WITHOUT_LIST } from '../../../constants/grades';

const ExamButton = ({ text, mode, ...restProps }) => (
  <a
    className={`${ce.examButton} ${ce[mode]}`}
    {...restProps}
    target="_blank"
    aria-description="Opens in a new window"
  >
    <span className={ce.read}>Read</span>
    <span className={ce.mainText}>{text}</span>
    <Icon.External color="currentColor" />
  </a>
);

export const renderEventDetails = ({
  event,
  allEvents,
  currentDate,
  largeTimeDisplay = false,
  mode = 'canvas',
  stylesheet,
  includeTimeDist = true, // currently, schedule page event details include time distance, while upcoming page doesn't bc the time dist is shown at the top of the card instead
  gradeScaleTypes,
  featureFlags,
}) => {
  const c = stylesheet;
  const isExam = event.eventType === 'exam';
  const data = event?.data || {};
  const { eventType, eventKind, hideDate } = event;
  const { status, staff, description, notes } = data;
  const location = getLocation(event.location);
  const numberOfAllowedAttempts = getNumberOfAllowedAttempts(
    data?.numberOfAttempts || data?.numberOfAllowedAttempts
  );
  const isAssessment = ASSESSMENT_EVENT_TYPES.includes(event.eventType);
  const startTime = event?.start?.time;
  const submissionStartDate = data.submissionStartDate?.time;
  const openDate = displayOpenDate({
    eventType,
    currentDate,
    openDate: submissionStartDate,
  });
  const displayStatus = getDisplayStatus({
    status,
    currentDate,
    submissionStartDate,
  });

  const isWorkshop = WORKSHOP_EVENT_TYPES.includes(eventType);
  const isStandardExamEvent = eventKind === 'standard' && isExam;
  const showTimeDistance =
    currentDate < startTime && displayStatus !== 'SUBMITTED';
  const isGraded = Boolean(data?.gradeFormatted);
  const gradesList = gradeScaleTypes?.[data?.gradeScaleType];
  const hasGradesList =
    gradesList &&
    !GRADE_SCALE_TYPES_WITHOUT_LIST.includes(data?.gradeScaleType);

  if (isWorkshop) {
    const linkedWorkshopEvents = getLinkedWorkshopEvents({ event, allEvents });

    return (
      <div className={c.workshopWrapper}>
        <WorkshopAccordion
          linkedWorkshopEvents={linkedWorkshopEvents}
          currentDate={currentDate}
          activeIndex={eventType === eventTypes.WORKSHOP_SUBMISSION ? 0 : 1}
          stylesheet={stylesheet}
          mode={mode}
          gradeScaleTypes={gradeScaleTypes}
          featureFlags={featureFlags}
        />
      </div>
    );
  }

  return (
    <>
      {openDate && (
        <div className={c.detailSection}>
          <div className={c.label}>Open date</div>
          <div className={fs.body}>{openDate}</div>
        </div>
      )}

      <DueDateOrTime
        isAssessment={isAssessment}
        largeTimeDisplay={largeTimeDisplay}
        event={event}
        stylesheet={stylesheet}
      />

      {!isAssessment && !hideDate && (
        <div className={c.detailSection}>
          <div className={c.label}>Date</div>
          <div className={fs.body}>
            {startTime ? getDisplayDate(currentDate, startTime) : '-'}
          </div>
        </div>
      )}

      {includeTimeDist && isAssessment && showTimeDistance && (
        <div className={c.detailSection}>
          <div className={c.label}>Due in</div>
          <div className={fs.body}>
            {startTime ? formatTimeDistance(startTime, currentDate) : '-'}
          </div>
        </div>
      )}

      {featureFlags?.GRADES && isGraded && (
        <div className={c.detailSection}>
          {hasGradesList ? (
            <GradesList
              items={[
                {
                  title: 'Grade',
                  content: renderGradesList(gradesList, data?.gradeFormatted),
                },
              ]}
              data={event.data}
            />
          ) : (
            <>
              <div className={c.label}>Grade</div>
              <div className={fs.body}>{renderGrade(event.data, false)}</div>
            </>
          )}
        </div>
      )}

      {displayStatus && (
        <div className={c.detailSection}>
          <div className={c.label}>Status</div>
          <div className={c.status}>
            {getDisplayStatusComponent({ status: displayStatus, mode })}
          </div>
        </div>
      )}

      {isAssessment && (
        <div className={c.detailSection}>
          <div className={c.label}>Type</div>
          <div>{capitaliseFirstWord(eventType)}</div>
        </div>
      )}

      {numberOfAllowedAttempts && (
        <div className={c.detailSection}>
          <div className={c.label}>Attempts allowed</div>
          <div>{numberOfAllowedAttempts}</div>
        </div>
      )}

      {isExam && (
        <div className={c.detailSection}>
          <div className={c.label}>Duration</div>
          <div>{getDuration(startTime, event?.end?.time)}</div>
        </div>
      )}

      {staff && (
        <div className={c.detailSection}>
          <div className={c.label}>Staff</div>
          <div>{staff}</div>
        </div>
      )}

      {description && (
        <div className={c.detailSection}>
          <div className={c.label}>Description</div>
          <div>{description}</div>
        </div>
      )}

      {location && (
        <div className={c.detailSection}>
          <div className={c.label}>Location</div>
          <div>{location}</div>
        </div>
      )}

      {notes && (
        <div className={c.detailSection}>
          <div className={c.label}>Notes</div>
          <div>{notes}</div>
        </div>
      )}

      {isStandardExamEvent && (
        <div className={c.detailSection}>
          <div className={c.label}>Useful information</div>
          <div className={`${ce.buttonContainer} ${ce[mode]}`}>
            <ExamButton
              text="How eExams work"
              href="https://www.monash.edu/students/admin/exams/electronic-exams/about"
              mode={mode}
            />
            <ExamButton
              text="eExams: help and support"
              href="https://www.monash.edu/students/admin/exams/electronic-exams/help"
              mode={mode}
            />
          </div>
        </div>
      )}
    </>
  );
};
